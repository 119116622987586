import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './TechnicalSupport.module.scss';
import Typography from '@material-ui/core/Typography';
import {
  FIRST_PARAGRAPH, FOURTH_PARAGRAPH, SECOND_PARAGRAPH, TECHNICAL_SUPPORT_ADVANTAGES_CONTENT,
  TECHNICAL_SUPPORT_DESCRIPTION, TECHNICAL_SUPPORT_HEADING, TECHNICAL_SUPPORT_INSIGHTS_HEADING,
  TECHNICAL_SUPPORT_MANAGED_CONTENT, TECHNICAL_SUPPORT_MANAGED_HEADING, TECHNICAL_SUPPORT_MANAGED_TEXT,
  TECHNICAL_SUPPORT_MULTI_TIERED_HEADING, TECHNICAL_SUPPORT_MULTI_TIERED_LIST, TECHNICAL_SUPPORT_MULTI_TIERED_TEXT,
  TECHNICAL_SUPPORT_SOFTWARE_CONTENT, TECHNICAL_SUPPORT_SOFTWARE_HEADING, TECHNICAL_SUPPORT_SOFTWARE_TEXT,
  TECHNICAL_SUPPORT_SUBHEADING, TECHNICAL_SUPPORT_TROUBLESHOOTING_CONTENT, TECHNICAL_SUPPORT_TROUBLESHOOTING_HEADING,
  TECHNICAL_SUPPORT_TROUBLESHOOTING_TEXT, THIRD_PARAGRAPH
} from '../../constants/services-pages';
import Advantages from '../Advantages';
import Feed from '../Feed';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import LeaveMessageBanner from '../LeaveMessageBanner';

const TechnicalSupport = () => {
  const { bg, icons, insightsPosts } = useStaticQuery(graphql`
    query {
      bg: allFile(
        filter: {
          sourceInstanceName: { eq: "technical-support" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      icons: allFile(
        filter: {
          sourceInstanceName: { eq: "technical-support" }
          extension: { eq: "svg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      insightsPosts: allMarkdownRemark(
        filter: {
          fields: {
            slug: {
              in: ["/posts/10-reasons-why-it-outsourcing-in-ukraine-is-a-perfect-choice-for-global-businesses", "/posts/google-cloud-migration-strategies-and-opportunities-beyond-usual-cloud-efficiencies", "/posts/building-a-modern-digital-enterprise-with-google-cloud-data-fusion"]
            }
          }
          frontmatter: {
            template: {
              eq: "post"
            }
            draft: {
              ne: true
            }
          }
        }
        ) {
          nodes {
            fields {
              slug
              categorySlug
              tagSlugs
            }
            frontmatter {
              title
              date
              category
              description
              socialImage
              tags
            }
          }
        }
    }
  `);

  const postsEdges = insightsPosts.nodes.map(node => ({node}));

  return (
    <div className={styles['technical-support']}>
      <InnerPagesUpperBanner
        bg={bg.nodes[0].publicURL}
        className={styles['support-banner']}
        description={TECHNICAL_SUPPORT_DESCRIPTION}
        descriptionClassName={styles['support-banner-description']}
        text={TECHNICAL_SUPPORT_HEADING}
        textClassName={styles['support-banner-text']}
      />

      <div className={`${styles['first-block']} ${styles['section-container']}`}>
        <div className={styles.container}>
          <Typography variant={'h2'} className={styles.heading} align="center" color="secondary">
            {TECHNICAL_SUPPORT_SUBHEADING}
          </Typography>

          <div className={styles['first-block-text']}>
            <div>
              <Typography className={styles['first-block-text-first-p']}>
                {FIRST_PARAGRAPH}
              </Typography>
              <Typography>{SECOND_PARAGRAPH}</Typography>
            </div>

            <div>
              <Typography>{THIRD_PARAGRAPH}</Typography>
              <Typography>{FOURTH_PARAGRAPH}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles['section-container']} ${styles.software}`}>
        <div className={styles.container}>
          <Typography variant="h2" className={styles.heading} align="center" color="secondary">
            {TECHNICAL_SUPPORT_SOFTWARE_HEADING}
          </Typography>
          <p className={styles['software-text']}>{TECHNICAL_SUPPORT_SOFTWARE_TEXT}</p>
          <div className={styles['support-grid']}>
            {TECHNICAL_SUPPORT_SOFTWARE_CONTENT.map(({icon, title}) => {
              return <div className={styles['support-grid-item']} key={icon}>
                <img
                  className={styles['support-grid-item-icon']}
                  src={icons.nodes.find(({name}) => name === icon).publicURL}
                />
                <div className={styles['support-grid-item-title']}>{title}</div>
              </div>;
            })}
          </div>
        </div>
      </div>
      <div className={`${styles['section-container']} ${styles['multi-tiered']}`}>
        <div className={styles.container}>
          <Typography variant="h2" className={styles.heading} align="center" color="secondary">
            {TECHNICAL_SUPPORT_MULTI_TIERED_HEADING}
          </Typography>
        </div>
      </div>
      <div className={styles['multi-tiered-card-container']}>
        <div className={styles['multi-tiered-card']}>
          <div className={styles['multi-tiered-text']}>{TECHNICAL_SUPPORT_MULTI_TIERED_TEXT}</div>
          <ul className={styles['multi-tiered-list']}>
            {TECHNICAL_SUPPORT_MULTI_TIERED_LIST.map((text, index) => {
              return <li className={styles['multi-tiered-list-item']} key={`multi-tiered-list-item-${index}`}>
                <span className={styles['multi-tiered-list-item-text']}>{text}</span>
              </li>;
            })}
          </ul>
        </div>
      </div>
      <div className={`${styles['section-container']} ${styles.troubleshooting}`}>
        <div className={styles.container}>
          <Typography variant="h2" className={styles.heading} align="center" color="secondary">
            {TECHNICAL_SUPPORT_TROUBLESHOOTING_HEADING}
          </Typography>
          <p className={styles['troubleshooting-text']}>{TECHNICAL_SUPPORT_TROUBLESHOOTING_TEXT}</p>
          <div className={styles['support-grid']}>
            {TECHNICAL_SUPPORT_TROUBLESHOOTING_CONTENT.map(({icon, title}) => {
              return <div className={styles['support-grid-item']} key={icon}>
                <img
                  className={styles['support-grid-item-icon']}
                  src={icons.nodes.find(({name}) => name === icon).publicURL}
                />
                <div className={styles['support-grid-item-title']}>{title}</div>
              </div>;
            })}
          </div>
        </div>
      </div>
      <div className={`${styles['section-container']} ${styles.managed}`}>
        <div className={styles.container}>
          <Typography variant="h2" className={styles.heading} align="center" color="secondary">
            {TECHNICAL_SUPPORT_MANAGED_HEADING}
          </Typography>
          <p className={styles['managed-text']}>{TECHNICAL_SUPPORT_MANAGED_TEXT}</p>
          <div className={styles['support-grid']}>
            {TECHNICAL_SUPPORT_MANAGED_CONTENT.map(({icon, title}) => {
              return <div className={styles['support-grid-item']} key={icon}>
                <img
                  className={styles['support-grid-item-icon']}
                  src={icons.nodes.find(({name}) => name === icon).publicURL}
                />
                <div className={styles['support-grid-item-title']}>{title}</div>
              </div>;
            })}
          </div>
        </div>
      </div>
      <div className={`${styles.advantages} ${styles['section-container']}`}>
        <div className={styles.container}>
          <Advantages content={TECHNICAL_SUPPORT_ADVANTAGES_CONTENT} listClassName={styles['advantages-list']} />
        </div>
      </div>
      <div className={`${styles.insights} ${styles['section-container']}`}>
        <div className={styles.container}>
          <Typography variant={'h2'} className={styles.heading} align="center" color="secondary">
            {TECHNICAL_SUPPORT_INSIGHTS_HEADING}
          </Typography>
          <Feed edges={postsEdges} />
        </div>
      </div>
      <LeaveMessageBanner />
    </div>
  );
};

export default TechnicalSupport;
