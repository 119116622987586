import React from 'react';
import Layout from '../components/Layout';
import { useSiteMetadata } from '../hooks';
import TechnicalSupport from '../components/Technical-support';
import {graphql, useStaticQuery} from "gatsby";

const ServicesTechnicalSupportTemplate = ({ location }) => {
  const { subtitle: siteSubtitle } = useSiteMetadata();
  const { bg } = useStaticQuery(graphql`
    query {
      bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "technical-support" } }) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  return (
    <Layout
      title="Technical support"
      description={siteSubtitle}
      location={location}
      socialImage={bg.nodes[0].publicURL}
    >
      <TechnicalSupport />
    </Layout>
  );
};

export default ServicesTechnicalSupportTemplate;
